::selection {
  background-color: transparent;
}

body {
  cursor: default;
}

main {
  transition: opacity 0.5s ease-in-out;
}

h1, h2 {
  background: linear-gradient(135deg, #30CFD0 0%, rgba(180, 0, 241, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hand {
  cursor: pointer;
}

.left {
  left: 0;
}

.right {
  right: 0;
  left: auto;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

.z3 {
  z-index: 3;
}

.progressBarRoot {
  height: 3px;
}

.progressBar {
  background: linear-gradient(-45deg, rgba(0, 255, 207, 1) 0%, rgba(0, 255, 254, 1) 25%, rgba(180, 0, 241, 1) 100%);
  transition: clip 1s ease-in-out;
  margin-top: -1px;
}

.notificationBadge {
  width: 30px;
  height: 30px;
  line-height: 1.75;
  top: 10px;
  right: 25px;
}

.sidePanel {
  transition: all 500ms ease-out;
}

#notificationPanel {
  right: -50%;
  width: 25%;
  min-width: 360px;
}

#notificationPanel.isVisible {
  right: 0;
}

#menuPanel {
  left: -50%;
  width: 50%;
}
#menuPanel.isVisible {
  left: 0;
}

@media (min-width: 1200px) {

}

/* import bootstrap to set changes */
@import "node_modules/bootstrap/scss/bootstrap";

.modal-content {
  border: none !important;
}
